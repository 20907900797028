.dashboard {
    display: flex;
    flex-direction: column;

    .card-header {
        font-weight: 500;
        font-size: 20.25px;
        line-height: 112%;
    }

    .month-selector {
        display: flex;
        justify-content: center;
        margin: 30px 0;
        text-align: center;
        font-weight: 500;
        font-size: 20.25px;
        line-height: 112%;
        .title {
            margin: 0 20px;
        }
        .chevron {
            &:hover {
                cursor: pointer;
            }
        }
    }
}