.patientDetails {
  .diagnosisList {
    table {
      width: 100%;
      border-collapse: collapse;
    }

    th, td {
      padding: 5px;
    }

    th {
      background-color: #deeaf1;
      text-align: left;
    }

    .condition-row {
      border-bottom: 1px solid #ccc;
      height: 48px;
      
      .condition-expand-icon {
        width: 10%;
      }
      
      .condition-name {
        width: 45%;
      }
      
      .condition-feedback-status {
        width: 45%;
      }
    }
  
    .feedback-select {
      width: 100%;
      padding: 0px;
      border: none;
      font-size: 14px;
      line-height: 1.5;

      & .MuiSelect-select {
        padding: 8px;
      }
    }
  
    .feedback-enabled {
      background-color: #d0ffd0;
    }
  
    .diagnosis-sublist {
      padding: 4px;
      background-color: #fff;
    }
  
    .diagnosis-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;
      border-bottom: 1px solid #ddd;
      min-height: 48px;
    }
  
    .icd-description, .status-label {
      padding-left: 8px;
    }

    .diagnosis-spacer {
      width: 10%;
    }
  
    .icd-code {
      width: 15%;
    }
  
    .icd-description {
      width: 65%;
      margin-right: 10px;
    }
  
    .status-label {
      width: 10%;
      text-align: center;
      border-radius: 4px;
      padding: 2px 4px;
      background-color: #f0f0f0;
    }
  }
}